import { lazy, Suspense, useEffect } from "react";
import { buildConfig } from "./config";
import { InternetRouter, foldInternetLocation } from "./internetRouter";
import { RemoteNoAuthAppProvider } from "./RemoteNoAuthAppProvider";
import { constNull } from "fp-ts/function";
import { AnonymousAuthProvider } from "./AnonymousAuthProvider";
import { Loader, useLibraryInfo } from "design-system";
import { BuildConfigProvider } from "./BuildConfigProvider";
import { ThirdPartyAppProvider } from "./ThirdPartyAppProvider";
import { option } from "fp-ts";
import { PageNotFound } from "./Common/PageNotFound";
import { PotentialClientAuthProvider } from "./PotentialClientAuthProvider";
import { OpenLoanApplicationForRemoteSigningWrapper } from "./Common/OpenLoanApplicationWrapper/OpenLoanApplicationForRemoteSigningWrapper";
import { SelfieTestPage } from "./UploadDocuments/MobileIdUpload/demo/SelfieTestPage";

const EmailActivationLink = lazy(
  () =>
    import("./PhoneAndEmailVerification/EmailVerification/EmailActivationLink")
);

const PendingApplicationEmailLink = lazy(
  () => import("./PendingApplicationEmailLink/PendingApplicationEmailLink")
);

const BankerFlowLinkData = lazy(
  () => import("./PendingApplicationEmailLink/BankerFlowLinkData")
);

const MobileUploadLinkVerify = lazy(
  () => import("./UploadDocuments/MobileIdUpload/MobileUploadLinkVerify")
);

const ClientRedirect = lazy(() => import("./ClientRedirect/ClientRedirect"));

const UserNotClientOwner = lazy(
  () => import("./ClientRedirect/UserNotClientOwner")
);

const RemoteOnboarding = lazy(
  () => import("./RemoteOnboarding/RemoteOnboarding")
);

const CredentialsMobile = lazy(
  () => import("./UKontoSecondPart/Credentials/CredentialsMobile")
);

const Mortgage = lazy(() => import("./Mortgage/Mortgage"));

const handleExit = () => {
  window.location.href = window.origin;
};

export function InternetApp() {
  const { getVersion } = useLibraryInfo();
  useEffect(() => {
    console.info(`Omnichannel Design System v${getVersion()}`);
  }, []);

  return (
    <BuildConfigProvider
      apiEndpoint={buildConfig.apiEndpoint}
      apiPort={buildConfig.apiPort}
    >
      <InternetRouter
        render={foldInternetLocation({
          NotFound: () => (
            <RemoteNoAuthAppProvider channel="PWS_Remote">
              <PageNotFound />
            </RemoteNoAuthAppProvider>
          ),
          MobileSelfieTest: () => (
            <AnonymousAuthProvider channel="PWS_Remote">
              <SelfieTestPage />
            </AnonymousAuthProvider>
          ),
          EmailVerification: ({ id }) => (
            // TODO(gio): PWS_Remote does not make sense for the email verification URL.
            // Channel should be retrieved via API here, similarly to how we do it for a mobile upload URL
            <RemoteNoAuthAppProvider channel="PWS_Remote">
              <Suspense fallback={constNull}>
                <EmailActivationLink uuid={id} />
              </Suspense>
            </RemoteNoAuthAppProvider>
          ),
          UploadDocumentsLinkWithBankerFlow: params => (
            <AnonymousAuthProvider channel="PWS_Remote">
              <Suspense fallback={constNull}>
                <BankerFlowLinkData
                  linkId={params.linkId}
                  content={(applicationId, bankerFlowId) => (
                    <PendingApplicationEmailLink
                      {...params}
                      bankerFlowId={option.some(bankerFlowId)}
                      applicationId={applicationId}
                      emailLinkType="UploadDocuments"
                      linkId={option.some(params.linkId)}
                    />
                  )}
                />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          UploadDocumentsLink: params => (
            <AnonymousAuthProvider channel="PWS_Remote">
              <Suspense fallback={constNull}>
                <PendingApplicationEmailLink
                  {...params}
                  bankerFlowId={option.none}
                  emailLinkType="UploadDocuments"
                  linkId={option.none}
                />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          PendingApplicationLink: params => (
            <AnonymousAuthProvider channel="PWS_Remote">
              <Suspense fallback={constNull}>
                <PendingApplicationEmailLink
                  {...params}
                  bankerFlowId={option.none}
                  emailLinkType="PendingApplication"
                  linkId={option.none}
                />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          MobileCreateProfile: params => (
            <AnonymousAuthProvider channel="3P_InPerson">
              <Suspense fallback={() => <Loader />}>
                <CredentialsMobile
                  {...params}
                  channel="3P_InPerson"
                  renderStandardLoan={() => <></>}
                />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          MobileCreateProfileAndSign: params => (
            <AnonymousAuthProvider channel="TLS_Remote">
              <Suspense fallback={() => <Loader />}>
                <CredentialsMobile
                  {...params}
                  channel="TLS_Remote"
                  renderStandardLoan={() => (
                    <PotentialClientAuthProvider channel="TLS_Remote">
                      <Suspense fallback={() => <Loader />}>
                        <OpenLoanApplicationForRemoteSigningWrapper />
                      </Suspense>
                    </PotentialClientAuthProvider>
                  )}
                />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          MobileIdUpload: ({ id }) => (
            /*
              Note: Simone
                AnonymousAuthProvider will create a dummy AppContext with channel=Branch.
                This is necessary because we need to verify the link and then call
                the endpoint to get the link parameters (that will contain the real channel to be used).
                To do this, we need the translated messages that depend on the channel.
                After we get the right channel from the link parameter endpoint,
                we will create a new AppContext with the right channel.
            */
            <AnonymousAuthProvider channel="Branch_InPerson">
              <Suspense fallback={() => <Loader />}>
                <MobileUploadLinkVerify id={id} type="DocumentUpload" />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          MobileProofOfIncomeUpload: ({ id }) => (
            <AnonymousAuthProvider channel="Branch_InPerson">
              <Suspense fallback={() => <Loader />}>
                <MobileUploadLinkVerify id={id} type="ProofOfIncomeUpload" />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          MobileSelfieCheck: ({ id }) => (
            <AnonymousAuthProvider channel="Branch_InPerson">
              <Suspense fallback={() => <Loader />}>
                <MobileUploadLinkVerify id={id} type="SelfieFraudCheck" />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          MobileHologramCheck: ({ id }) => (
            <AnonymousAuthProvider channel="Branch_InPerson">
              <Suspense fallback={() => <Loader />}>
                <MobileUploadLinkVerify id={id} type="HologramFraudCheck" />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          MobileFullCheck: ({ id }) => (
            <AnonymousAuthProvider channel="Branch_InPerson">
              <Suspense fallback={() => <Loader />}>
                <MobileUploadLinkVerify
                  id={id}
                  type="HologramAndSelfieFraudCheck"
                />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          ClientRedirect: params => (
            <Suspense fallback={constNull}>
              <ClientRedirect {...params} />
            </Suspense>
          ),
          UserNotClientOwner: params => (
            <Suspense fallback={constNull}>
              <UserNotClientOwner {...params} />
            </Suspense>
          ),
          RemoteOnboarding: data => (
            <AnonymousAuthProvider channel="PWS_Remote">
              <Suspense fallback={constNull}>
                <RemoteOnboarding
                  product={data.product}
                  optionalParams={{
                    promo: data.promo,
                    calculatorData: data["calculator-data"],
                  }}
                />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          OpenApplication: ({ encryptedApplicationId }) => (
            <ThirdPartyAppProvider
              showPasswordWasResetMessage={false}
              authInfo={option.none}
            >
              {_ => (
                <Suspense fallback={constNull}>
                  <Mortgage
                    isThirdParty
                    existing
                    openFromLink
                    applicationId={encryptedApplicationId}
                    onExit={handleExit}
                    isExistingClient={false}
                  />
                </Suspense>
              )}
            </ThirdPartyAppProvider>
          ),
        })}
      />
    </BuildConfigProvider>
  );
}
